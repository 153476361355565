/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/function-component-definition */
import CallEndIcon from "@mui/icons-material/CallEnd";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import MinimizeIcon from "@mui/icons-material/Minimize";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import NumberPaid from "components/NumberPaid";
import { useSnackbar } from "components/snackbar";
import useDecodedData from "hooks/useDecodedData";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useCallManagerActions } from "redux/call-manager/callManager";
import { RootState } from "redux/store";
import palette from "theme/palette";
import { isExistDnc } from "utils";
import useCallManager from "../hook/useCallManager";
import { useFetchVirtualNumbers } from "../query/useFetchVirtualNumbers";
import { useStyles } from "../useStyles";

export interface IDialer {
  handleOutGoingCall: (value: string, toId: number, type: string) => void;
  open: boolean;
}

function DialerManager(props: IDialer) {
  const { handleOutGoingCall, open } = props;
  const callManagerData = useCallManager();
  const inputRef: any = React.useRef();
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      inputRef?.current?.focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);
  const decoded = useDecodedData();

  const dncListData = useSelector((state: RootState) => state.dnc);
  const { dncList } = dncListData;
  const snackbar = useSnackbar();
  const [callerId, setCallerId] = useState("");
  const [minimize, setMinimize] = useState(false);
  const [result, setResult] = useState("");

  const chats = useSelector((state: RootState) => state.chats);
  const { selectedChatRoomId } = chats;

  const { setCallManagerDialer, setCallerIds } = useCallManagerActions();

  const {
    data: callerIds,
    refetch,
    isLoading,
  } = useFetchVirtualNumbers(0, false);

  useEffect(() => {
    setMinimize(false);
  }, [open]);

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open]);

  const callIds = useMemo(
    () =>
      callerIds?.data?.map((item: any) => {
        return {
          label: item,
        };
      }),
    [callerIds],
  );

  const numbers: any = useMemo(
    () =>
      callManagerData?.toNumbers?.map((item: any) => {
        return {
          label: item,
        };
      }),
    [callManagerData?.toNumbers],
  );
  useEffect(() => {
    if (callIds) {
      setCallerId(callIds?.[0]?.label);
      setCallerIds({ callerIds: callIds });
    }
  }, [callIds]);
  useEffect(() => {
    if (numbers) {
      setResult(
        numbers?.[0]?.label === ""
          ? numbers?.[1]?.label?.replace(/\D/g, "")
          : numbers?.[0]?.label?.replace(/\D/g, ""),
      );
    }
  }, [numbers]);

  const handleClose = () => {
    setCallManagerDialer({ dialer: false });
  };
  const classes = useStyles();
  const handleClick = (number: number | string) => {
    let tempText = result;
    tempText += number;
    console.log(tempText);
    setResult(tempText);
  };
  const clearDisplay = () => {
    setResult("");
  };

  const handleminimize = () => {
    setMinimize(!minimize);
  };

  const icons: React.ReactNode | any = () => {
    return (
      <InputAdornment position="end">
        <HighlightOffOutlinedIcon
          sx={{ cursor: "pointer" }}
          onClick={clearDisplay}
        />
      </InputAdornment>
    );
  };

  return (
    // <Dialog open={open} onClose={handleClose}>
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      autoHideDuration={6000}
      open={open}
      style={{
        zIndex: 99999,
        bottom: 10,
        right: selectedChatRoomId ? "877px" : "360px",
      }}
    >
      <Box sx={{ backgroundColor: "#ffffff", boxShadow: 10 }}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{ paddingLeft: "10px", p: "10px" }}
        >
          <Box>
            <Typography sx={{ fontWeight: "600", fontSize: "20px" }}>
              Dialer
            </Typography>
          </Box>
          <Box>
            <IconButton onClick={handleminimize}>
              {minimize ? <CheckBoxOutlineBlankIcon /> : <MinimizeIcon />}
            </IconButton>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Stack>
        {!minimize ? (
          <>
            <Box sx={{ margin: "0px 14px" }}>
              <TextField
                autoFocus
                InputProps={{
                  endAdornment: icons,
                }}
                // inputRef={inputRef}
                className={classes.phoneNumber_TextField}
                id="outlined-basic"
                placeholder="Phone Number"
                type="number"
                value={result}
                variant="outlined"
                onChange={(e) => {
                  setResult(e.target.value);
                }}
                onFocus={(e) => e.currentTarget.select()}
              />
            </Box>
            <Stack
              direction="column"
              sx={{
                px: "10px",
                mt: "10px",
                display: numbers ? "block" : "none",
              }}
            >
              <Box>
                <Typography sx={{ mb: "-5px" }}>Call to</Typography>

                <Autocomplete
                  disablePortal
                  className={classes.callFrom_Container}
                  defaultValue={
                    numbers?.[0]?.label === "" ? numbers?.[1] : numbers?.[0]
                  }
                  id="combo-box-demo"
                  options={numbers || []}
                  renderInput={(params) => <TextField {...params} />}
                  renderOption={(props: any, option: any) => {
                    return (
                      <Box
                        {...props}
                        F
                        sx={{ p: "5px" }}
                        onClick={(event: any) => {
                          if (
                            !isExistDnc(
                              dncList || [],
                              option?.label?.replace(/\D/g, ""),
                            )
                          ) {
                            props?.onClick(event);
                          }
                        }}
                      >
                        <Typography
                          sx={{
                            color: isExistDnc(
                              dncList || [],
                              option?.label?.replace(/\D/g, ""),
                            )
                              ? "#ddd"
                              : "#000",
                          }}
                        >
                          {option?.label}{" "}
                        </Typography>
                        <span
                          style={{
                            color: palette.error.main,
                            marginLeft: "5px",
                          }}
                        >
                          {isExistDnc(
                            dncList || [],
                            option?.label?.replace(/\D/g, ""),
                          )
                            ? "This no set as DNC"
                            : ""}
                        </span>
                      </Box>
                    );
                  }}
                  sx={{
                    maxWidth: { xs: "100%", width: "14rem" },
                    width: "100%",
                  }}
                  onChange={(event, values: any) => {
                    if (values) {
                      setResult(values?.label?.replace(/\D/g, ""));
                    }
                  }}
                />
              </Box>
            </Stack>
            {/* main-body */}
            <NumberPaid handleClick={handleClick} />
            {/* footer */}
            <Box>
              {/* <Stack
                direction="column"
                sx={{ px: "10px", mt: "10px", width: "366px" }}
              >
                <Box>
                  <Typography sx={{ mb: "-5px" }}>Call from</Typography>
                  {isLoading ? (
                    <Typography sx={{ fontSize: "14px" }}>
                      Getting Caller Id... <CircularProgress size={14} />
                    </Typography>
                  ) : (
                    <Autocomplete
                      disablePortal
                      className={classes.callFrom_Container}
                      defaultValue={callIds?.[0]}
                      id="combo-box-demo"
                      loading={loading}
                      options={callIds}
                      renderInput={(params) => <TextField {...params} />}
                      sx={{
                        maxWidth: { xs: "100%", width: "14rem" },
                        width: "100%",
                      }}
                      onChange={(event, values) => {
                        if (values) {
                          setCallerId(values.label);
                        }
                      }}
                    />
                  )}
                </Box>
              </Stack> */}
              <Box sx={{ marginTop: "0.8rem" }}>
                <Button
                  fullWidth
                  className={classes.callEndIcon_Container}
                  onClick={() => {
                    if (isExistDnc(dncList || [], result)) {
                      snackbar?.show({
                        title: "This number is set as DNC",
                        type: "error",
                      });
                    } else {
                      handleOutGoingCall(result, 0, "other");
                    }
                  }}
                >
                  <CallEndIcon fontSize="large" sx={{ color: "#fff" }} />
                </Button>
              </Box>
            </Box>
          </>
        ) : null}
      </Box>
    </Snackbar>

    // {/* </Dialog> */ }
  );
}

export default DialerManager;
