/* eslint-disable import/no-extraneous-dependencies */
import { useContext, useEffect, useState } from "react";
/* eslint-disable import/no-extraneous-dependencies */
import { SocketContext } from "SocketContex";
import InComingConfCall from "components/incoming-conf-call";
import Notification from "components/notification-toaster";
import VideoCallProvider from "components/video-call-provider";
import DraggableButton from "draggable-button/AccountActivity";
import { APP_ROUTES, AUTH_ROUTES } from "navigation/Routes";
import CallProvider from "pages/call-manager/CallProvider";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import { useChatUsersActions } from "redux/chat-users-list/chatUsersList";
import { RootState } from "redux/store";
import "./App.css";

function App() {
  const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);
  const { resetCallDuration } = useChatUsersActions();
  const [isNotificationSupported, setNotificationSupported] = useState(false);
  const { setChatRooms } = useContext(SocketContext);
  const routing = useRoutes(isLoggedIn ? APP_ROUTES : AUTH_ROUTES);

  useEffect(() => {
    resetCallDuration();
    setChatRooms([]);
  }, []);

  return (
    <div>
      {/* <Routes /> */}
      {routing}
      {isNotificationSupported ? <Notification /> : null}
      <VideoCallProvider />
      <InComingConfCall />
      <CallProvider />
      <DraggableButton />
      {/* <TwilioCallRinging /> */}
    </div>
  );
}

export default App;
