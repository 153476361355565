import { PER_PAGE } from "constants/constants";
import { IResponse } from "constants/interfaces";
import {
  IUpdate,
  IUserRequest,
} from "pages/admin/settings/screens/team/query/useApiAction";
import { IResponseRole } from "pages/admin/settings/screens/team/query/useFetchRoles";
import { IResponseUser } from "pages/admin/settings/screens/team/query/useFetchUsers";
import { IUpdatePasswordRequest } from "pages/admin/settings/screens/team/query/useUpdatePassword";
import { IResponseUserById } from "pages/user/inbox/query/useFetchUserById";
import client from "utils/ApiClient";
import API_URLS from "./endPoints";

export interface IAdminToggleUser {
  userId?: number;
  isEmail?: boolean | null;
  isSms?: boolean | null;
  isTranscription?: boolean | null;
  isRecording?: boolean | null;
  role?: string | null;
  toggleById?: number | null;
}
async function saveUser(body: IUserRequest): Promise<IResponse> {
  return body?.id
    ? client.put(API_URLS.EDIT_USER, body)
    : client.post(API_URLS.ADD_USER, body);
}

async function activeInactive(userId: number): Promise<IResponse> {
  return client.post(`${API_URLS.ACTIVE_DEACTIVE}?Id=${userId}`);
}
async function fetchUserByRole(userId: number): Promise<IResponse> {
  return client.get(`${API_URLS.GET_USEROLESBY_USERID}?id=${userId}`);
}

async function removeUser(userId: number): Promise<IResponse> {
  return client.delete(`${API_URLS.DELETE_USER}/${userId}`);
}

async function getRoles(): Promise<IResponseRole> {
  return client.get(`${API_URLS.GET_ROLES}`);
}
async function fetchUserBId(id: number): Promise<IResponseUserById> {
  return client.get(`${API_URLS.GET_USER_BY_ID}?id=${id}`);
}

async function fetchRoomDetails(id: number): Promise<IResponseRole> {
  return client.get(`${API_URLS.GET_ROOM_DETAILS}?roomId=${id}`);
}

async function postWhoIsTyping(data: any): Promise<IResponseRole> {
  return client.post(`${API_URLS.POST_WHO_IS_TYPING}`, data);
}

async function updateCallMessage(data: any): Promise<IResponseRole> {
  return client.put(`${API_URLS.UPDATE_CALL_MESSAGE}`, data);
}

async function fetchUsers(
  pageNo: number,
  pageLimit = 10,
  search = "",
  roleId?: number,
): Promise<IResponseUser> {
  let url =
    pageLimit === 0
      ? `${API_URLS.FETCH_USERS}`
      : `${API_URLS.FETCH_USERS}?page=${pageNo + 1}&pageSize=${
          pageLimit || PER_PAGE
        }`;
  if (roleId) {
    url = `${url}&roleId=${roleId}`;
  }
  if (search) {
    url = `${url}&search=${search}`;
  }
  return client.get(url);
}

async function updateUser(body: IUpdate): Promise<IResponse> {
  return client.post(API_URLS.UPDATE_CONNECTION, body);
}

async function updatePassword(
  body: IUpdatePasswordRequest,
): Promise<IResponse> {
  return client.put(API_URLS.UPDATE_PASSWORD, body);
}

async function toggleUserByAdmin(body: IAdminToggleUser): Promise<IResponse> {
  const url = `${API_URLS.TOGGLE_USER_BY_ADMIN}?userId=${body.userId}&role=${body.role}&toggleById=${body.toggleById}&isEmail=${body.isEmail}&isSms=${body.isSms}&isTranscription=${body.isTranscription}&isRecording=${body.isRecording}`;
  return client.put(url);
}

async function fetchAsList(search: string) {
  return client.get(
    `${API_URLS.GET_AS_LIST}?page=1&pageSize=30&search=${search}`,
  );
}
export {
  activeInactive,
  fetchAsList,
  fetchRoomDetails,
  fetchUserBId,
  fetchUserByRole,
  fetchUsers,
  getRoles,
  postWhoIsTyping,
  removeUser,
  saveUser,
  toggleUserByAdmin,
  updateCallMessage,
  updatePassword,
  updateUser,
};
