import { FILE_URL } from "config";
import moment from "moment";
import { COLOR_AS_STATUS, COLOR_AS_STATUS_FOR_HTML, textEllipsis } from "utils";
import AUDIO from "../assets/images/documents/audio-icon.png";
import XLXS from "../assets/images/documents/excel-icon.png";
import EmailVoice from "../assets/images/documents/icons-audio-attach.png";
import EmailDocs from "../assets/images/documents/icons-doc.png";
import EmailGoogleSheet from "../assets/images/documents/icons-google-sheets.png";
import EmailImg from "../assets/images/documents/icons-img.png";
import EmailPdf from "../assets/images/documents/icons-pdf.png";
import EmailVideo from "../assets/images/documents/icons-video.png";
import EmailZip from "../assets/images/documents/icons-zip.png";
import NOT_SUPPORTED from "../assets/images/documents/not-supported.png";
import PDF from "../assets/images/documents/pdf-icon.png";
import DOC from "../assets/images/documents/text-icon.png";
import USER from "../assets/images/documents/user-icon.png";
import VIDEO from "../assets/images/documents/video-camera.png";
import ZIP from "../assets/images/documents/zip-icon.png";
import NoDataFound from "../assets/images/inbox-no-data.png";

export const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 5000000;
export const KILO_BYTES_PER_BYTE = 1000;
export const PER_PAGE = 10;
// EMAIL REGEX
// /^[^@]+@[@]+\.[^@\.]{2,}$/
export const EMAIL_REGEX = /^[^@]+@[@]+\.[^@.]{2,}$/;
export const NAME_REGEX = /^(?=.*[a-z])[a-zA-Z0-9' -]*$/;
export const NAME_REGEX_2 = /^[a-zA-Z]{2}[a-zA-Z0-9' -]*$/;
export const NAME_REGEX_3 = /^(?=.*[a-z])[a-zA-Z' -]*$/;
export const USERNAME_REGEX = /^[a-zA-Z]{1}[a-zA-Z0-9 -]*$/;
export const ONLY_ALPHABETS_SPACES = /[^a-zA-Z\s]/gi;
export const ONLY_TEXT = /^[a-zA-Z\s]*[a-zA-Z][a-zA-Z\s]*$/;
export const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,128})/;
export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const PDF_ICONS = PDF;
export const DOC_ICON = DOC;
export const DOCX_ICON = DOC;
export const TXT_ICON = DOC;
export const XLXS_ICON = XLXS;
export const AUDIO_ICON = AUDIO;
export const VIDEO_ICON = VIDEO;
export const ZIP_ICON = ZIP;
export const NOT_SUPPORTED_ICON = NOT_SUPPORTED;
export const USER_ICON = USER;
export const NO_DATA_IMG = NoDataFound;

export const EmailAttachDocs = EmailDocs;
export const EmailAttachImg = EmailImg;
export const EmailAttachZip = EmailZip;
export const EmailAttachPdf = EmailPdf;
export const EmailAttachGoogleSheet = EmailGoogleSheet;
export const EmailAttachVideo = EmailVideo;
export const EmailAttachVoice = EmailVoice;

export const CALL_STATUS: any = {
  "participant-leave": "Completed",
  "participant-join": "In Call",
  "conference-start": "Calling",
};

export const STATUS_BACK: any = {
  "participant-leave": "#fff",
  "In Call": "rgba(2, 217, 67, 0.7)",
  "Call Wraping": "rgba(255, 0, 0, 0.7)",
};

export const CALL_STATUS_COLOR: any = {
  Outgoing: "info",
  Incoming: "success",
  "Missed Call": "error",
};

export const URL_REGEX =
  /^(([^:/?#]+):)?(\/\/([^/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?/;

export const US_PHONE_NUMBER_REGEX =
  /^((\()?[1-9]{1}[0-9]{2}(\))?)[\s-]?[0-9]{3}[\s-]?[0-9]{4}$/;
export const FACEBOOK_URL_REGEX =
  /(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-.]*\/)*([\w\-.]*)/;
export const INSTAGRAM_URL_REGEX =
  /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/gim;
export const LINKEDIN_URL_REGEX =
  /((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^/]+\/(([\w|\d-&#?=])+\/?){1,}))$/gm;
export const TWITTER_URL_REGEX =
  /(https:\/\/twitter.com\/(?![a-zA-Z0-9_]+\/)([a-zA-Z0-9_]+))/;
// "https://play-lh.googleusercontent.com/IkcyuPcrQlDsv62dwGqteL_0K_Rt2BUTXfV3_vR4VmAGo-WSCfT2FgHdCBUsMw3TPGU";

export const ADMIN_ROLE = ["SuperAdmin"];
export const TempAdmin = ["SuperAdmin", "Admin", "Sales"];

export const monthCount = [
  {
    id: 1,
    value: "1",
  },
  {
    id: 2,
    value: "2",
  },
  {
    id: 3,
    value: "3",
  },
  {
    id: 4,
    value: "4",
  },
  {
    id: 5,
    value: "5",
  },
  {
    id: 6,
    value: "6",
  },
  {
    id: 7,
    value: "7",
  },
  {
    id: 8,
    value: "8",
  },
  {
    id: 9,
    value: "9",
  },
  {
    id: 10,
    value: "10",
  },
  {
    id: 11,
    value: "11",
  },
  {
    id: 12,
    value: "12",
  },
];

export const emailEngagement = [
  { id: "none", value: "All" },
  { id: "never", value: "Email Not Opened" },
  { id: "Once", value: "Email Opened Once" },
  { id: "Multiple", value: "Email Opened Multiple Times" },
];

export const callEngagement = [
  { id: "none", value: "All" },
  { id: "Never", value: "Never" },
  { id: "Once", value: "Call Repeat Once" },
  { id: "Multiple", value: "Call Repeat Multiple " },
];

export const linkEngagement = [
  { id: "none", value: "All" },
  { id: "Never", value: "Never" },
  { id: "Once", value: "clicked once" },
  { id: "Multiple", value: "clicked many" },
];

export const paymentStatusData = [
  { id: 1, value: "Active" },
  { id: 2, value: "Inactive" },
];

export const UNIQUE_CODE_TEXTS = [
  "Follow Up",
  "Service Interested In",
  "Sales Rep",
  "Company Name",
  "Lead Source",
  "Status",
  "Phone",
  "Extension",
  "2nd Office Line",
  "2nd Email Address",
  "Description",
  "Date Of Birth",
  "Referred By",
  "Role",
  "Fax Number",
  "Website Address",
  "Record Owner",
  "EIN or SS #",
  "Entity Type",
  "Business Address",
  "Email Follow Up Options",
  "Location Told Customer",
  "%email%",
  "%address%",
  "%fullname%",
  "%fullname% ",
  "%fullname %",
  "%mobile%",
  "%firstName%",
  "%lastName%",
  "%companyName%",
  "%fullname%Name ",
  "%fullname%Name",
  "%alternatePhone%",
  "%secondEmail%",
  "%leadSourceName%",
  "%leadStatusName%",
  "%companyWebsite%",
  "%createdByName%",
  "%updatedByName%",
  "%lastContact%",
  "%lastCallMade%",
  "%lastCallTime%",
  "%twitter%",
  "%linkedIn%",
  "%instagram%",
  "%facebook%",
  "%activityDate%",
  "%street%",
  "%state%",
  "%city%",
  "%zipCode%",
  "%employeeType%",
  "%serviceAgreementMonth%",
  "%serviceAgreementDays%",
  "%hoursPerDayWork%",
  "%virtualEmployees%",
  "%totalHoursPerMonth%",
  "%totalHoursPerCycle%",
  "%daysPriorTocancel%",
  "%setupFee%",
  "%rate%",
  "%perAppointmentFee%",
];
export const dncModules = [
  "Contacts",
  "Contact Details",
  "Customer Details",
  "Call Campaign Details",
];

export const preferencesConstants = {
  columns: "columns",
  contactMyList: "contact-myList",
  tablePage: "table-page",
  cutOffTime: "cut-off-time",
  selectCallerId: "selectCallerId",
  templateTabs: "templateTabs",
  allConversation: "all-conversation",
  emailConfigure: "email-configure",
  todayCallActivity: "today-call-activity",
  callCampaignCustomer: "call-campaign-customer",
  activityCalendar: "activity-calendar",
};

export const selectType = [
  { id: 1, name: "Today", value: "ToDay" },
  { id: 2, name: "This Week", value: "ThisWeek" },
  { id: 3, name: "This Month", value: "ThisMonth" },
  { id: 4, name: "Last Month", value: "LastMonth" },
  { id: 5, name: "Last Three Month", value: "LastThreeMonths" },
];
export const FIELDS = [
  { value: "FullName", id: "%fullname%" },
  { value: "Mobile", id: "%mobile%" },
  { value: "Email", id: "%email%" },
  { value: "First Name", id: "%firstName%" },
  { value: "Last Name", id: "%lastName%" },
  { value: " Business Address", id: "%address%" },
];

export const AGREEMENT_FIELDS = [
  { value: "Custom Text Field", id: "%customText%" },
  { value: "Custom Text Field Area", id: "%customTextArea%" },
  { value: "Custom Date Time Field", id: "%customDateTime%" },
  { value: "Custom Date Field", id: "%customDate%" },
  { value: "Custom Dropdown Field", id: "%customDropdown%" },
  { value: "Get Signature", id: "%%signature_here%%" },
  { value: "Get Initial", id: "%%initial_here%%" },
  { value: "FullName", id: "%fullname%" },
  { value: "Mobile", id: "%mobile%" },
  { value: "Email", id: "%email%" },
  { value: "First Name", id: "%firstName%" },
  { value: "Last Name", id: "%lastName%" },
  { value: "Business Address", id: "%address%" },
  { value: "Company Name", id: "%companyName%" },
  { value: "Alternative Number", id: "%alternatePhone%" },
  { value: "Second Email", id: "%secondEmail%" },
  { value: "Lead Source", id: "%leadSourceName%" },
  { value: "Lead Status", id: "%leadStatusName%" },
  { value: "Company Website", id: "%companyWebsite%" },
  { value: "Created By", id: "%createdByName%" },
  { value: "Updated By", id: "%updatedByName%" },
  { value: "Last Contact", id: "%lastContact%" },
  { value: "Last Call Made", id: "%lastCallMade%" },
  { value: "Last Call Time", id: "%lastCallTime%" },
  { value: "Twitter", id: "%twitter%" },
  { value: "LinkedIn", id: "%linkedIn%" },
  { value: "Instagram", id: "%instagram%" },
  { value: "Facebook", id: "%facebook%" },
  { value: "Activity Date", id: "%activityDate%" },

  // address
  { value: "Street", id: "%street%" },
  { value: "State", id: "%state%" },
  { value: "City", id: "%city%" },
  { value: "ZipCode", id: "%zipCode%" },

  // contractDetails
  { value: "Employee Type", id: "%employeeType%" },
  { value: "Service Agreement Month", id: "%serviceAgreementMonth%" },
  { value: "Service Agreement Days", id: "%serviceAgreementDays%" },
  { value: "Hours Per Day Work", id: "%hoursPerDayWork%" },
  { value: "Virtual Employees", id: "%virtualEmployees%" },
  { value: "Total Hours Per Month", id: "%totalHoursPerMonth%" },
  { value: "Total Hours Per Cycle", id: "%totalHoursPerCycle%" },
  { value: "Days Prior To cancel", id: "%daysPriorTocancel%" },
  { value: "Setup Fee", id: "%setupFee%" },
  { value: "Rate", id: "%rate%" },
  { value: "Per Appointment Fee", id: "%perAppointmentFee%" },
];

export const DIALER_DIGITS = {
  Raw1: [1, 2, 3],
  Raw2: [4, 5, 6],
  Raw3: [7, 8, 9],
  Raw4: ["*", 0, "#"],
};
export const CALL_MESSAGES = ["Call", "Video Call", "Voice Call", "Group Call"];

export const invoicesHtMl = (
  invoiceData: any,
  payableAmount: any,
  paymentLink = "",
  logoUrl = "",
) => {
  const checkTermsCondition = invoiceData?.termsConditions;
  const termsCondition = invoiceData?.companyInvoiceSetting?.terms;
  const combinedText = termsCondition || checkTermsCondition;
  const futurePayments =
    invoiceData?.paymentFuture || invoiceData?.futurePayments;
  const paymentButton = paymentLink
    ? `<p style="text-align: center;">
  <a href="${paymentLink}" style="display: inline-block; padding: 10px 20px; text-decoration: none; background-color: #007bff; color: #fff; border-radius: 3px;">Pay Now</a>
</p>`
    : "";
  const header = `
  <div>
  <div style="display: flex;justify-content: space-between;margin: 0px;">
      <div>
         ${logoUrl && `<img loading="lazy" src="${FILE_URL}/${logoUrl}" style="width:auto; height: 50px;" alt="logo">`}
          <p style="font-size: 12px;font-weight: 600;margin-top: 15px">${
            invoiceData?.invoiceNumber || ""
          }</p>
          <p style="font-size: 28px;margin-top: 5px;margin-bottom: 5px;color: #141314;font-weight: 700;">
              $${payableAmount?.toFixed(2) || "00"}</p>
          <p style="font-size: 12px;font-weight: 100;margin-top: 5px;">Due Amount</p>
      </div>
  
      <div style="width: 100px;margin-left:auto">
          <div style="background-color:${
            COLOR_AS_STATUS[invoiceData?.paymentPaidStatus || "Due"]
          };">
              <p style="font-size: 14px;color: white; text-align: center;padding: 8px;">
              ${invoiceData?.paymentPaidStatus || "Due"}</p>
          </div>
      </div>
  </div>
  
  </div>
  <div style="height: 2px; background-color: #e6e8f0;margin-bottom: 10px;"></div>  
  `;

  const invoicesItems = invoiceData?.paymentInvoiceItems?.map((item: any) => {
    const total = item?.totalAmount || item?.total;
    return `<tr>
    <td style="font-size: 13px;color: #374151;padding-bottom: 10px;">${
      textEllipsis(item?.title, 20) || "-"
    }</td>
    <td style="font-size: 13px;color: #374151;padding-bottom: 10px;">${
      item?.quantity
    }</td>
    <td style="font-size: 13px;color: #374151;padding-bottom: 10px;">$${Number(
      item?.amount,
    )?.toFixed(2)}</td>
    <td style="font-size: 13px;color: #374151;padding-bottom: 10px;"></td>
    <td style="font-size: 13px;color: #374151;padding-bottom: 10px;">$${total?.toFixed(
      2,
    )}</td>
  </tr>`;
  });

  const invoiceTable = `<table style="width:100%;text-align: start;border-spacing: 0px">
  <tbody><tr style="font-family:sans-serif;">
      <td style="font-size: 12px;color: #374151;text-align: start;font-weight: 700;padding-bottom: 10px;">
          ITEM
      </td>
      <td style="font-size: 12px;color: #374151;text-align: start;font-weight: 700;padding-bottom: 10px;">
          QUANTITY</td>
      <td style="font-size: 12px;color: #374151;text-align: start;font-weight: 700;padding-bottom: 10px;">
          AMOUNT</td>
      <td></td>
      <td style="font-size: 12px;color: #374151;text-align: start;font-weight: 700;padding-bottom: 10px;">
          TOTAL</td>
  </tr>
  ${invoicesItems?.toString()?.replace(/[,]/g, "")}
  
  <tr>
      <td></td>
      <td style="font-size: 13px;color: #374151;padding-bottom: 10px;">Sub Total</td>
      <td></td>
      <td style="font-size: 13px;color: #374151;padding-bottom: 10px;">$${
        invoiceData?.totalAmount?.toFixed(2) || 0
      }</td>
      <td></td>
  </tr>
  <tr>
      <td></td>
      <td style="font-size: 13px;color: #141314; font-weight: 800;padding-bottom: 10px;">Total</td>
      <td></td>
      <td style="font-size: 13px;color: #141314; font-weight: 800;padding-bottom: 10px;">$${(
        (invoiceData?.totalAmount || 0) + (invoiceData?.creditFee || 0)
      )?.toFixed(2)}</</td>
      <td></td>
  </tr>
  <tr>
      <td></td>
      <td style="font-size: 13px;color: #374151;padding-bottom: 10px;">Credit Card Processing Fee :</td>
      <td></td>
      <td style="font-size: 13px;color: #374151;padding-bottom: 10px;">$${
        invoiceData?.creditFee?.toFixed(2) || 0
      }</td>
      <td></td>
  </tr>
  
  <tr>
      <td></td>
      <td style="font-size: 13px;color: #374151;padding-bottom: 10px;">Payment Made</td>
      <td></td>
      <td style="font-size: 13px;color:#F04461;padding-bottom: 10px;">-$${
        invoiceData?.paymentMade?.toFixed(2) || 0
      }</td>
      <td></td>
  </tr>
  
  <tr style="background-color: #cccccc;">
      <td></td>
      <td style="font-size: 13px;color: #141314; font-weight: 800;padding-top: 5px;padding-bottom: 5px;">
          Balance Due</td>
      <td></td>
      <td style="font-size: 13px;color: #141314; font-weight: 800;padding-top: 5px;padding-bottom: 5px;">
     $${(
       (invoiceData?.totalAmount || 0) - (invoiceData?.paymentMade || 0)
     )?.toFixed(2)}</td>
      <td></td>
  </tr>
    <tr>
      <td></td>
      <td style="font-size: 13px;color: #374151;padding-bottom: 10px;">Invoice Date :</td>
      <td></td>
      <td style="font-size: 13px;color: #374151;padding-bottom: 10px;">$${
        invoiceData?.invoiceDate || 0
      }</td>
      <td></td>
  </tr>
    <tr>
      <td></td>
      <td style="font-size: 13px;color: #374151;padding-bottom: 10px;">Due Date :</td>
      <td></td>
      <td style="font-size: 13px;color: #374151;padding-bottom: 10px;">$${
        invoiceData?.dueDate || 0
      }</td>
      <td></td>
  </tr>
    <tr>
      <td></td>
      <td style="font-size: 13px;color: #374151;padding-bottom: 10px;">Paid Date :</td>
      <td></td>
      <td style="font-size: 13px;color: #374151;padding-bottom: 10px;">$${
        invoiceData?.paid || 0
      }</td>
      <td></td>
  </tr>
  </tbody></table>
  <br>
  `;

  const itemsPayments = futurePayments?.map((data: any, index: any) => {
    return `<tr>
    <td style="text-align: start;font-size: 14px;color: #141314;padding-bottom: 10px;padding-top: 10px;">${
      index + 1
    }</td>
    <td style="text-align: start;font-size: 14px;color: #141314;padding-bottom: 10px;padding-top: 10px;">$${(
      data?.amount || 0
    )?.toFixed(2)}</td>
    <td style="text-align: start;font-size: 14px;color: #141314;padding-bottom: 10px;padding-top: 10px;">$${(
      data?.cardFee || 0
    )?.toFixed(2)}</td>
    <td style="text-align: start;font-size: 14px;color: #141314;padding-bottom: 10px;padding-top: 10px;">${(
      (data?.amount || 0) + (data?.cardFee || 0)
    )?.toFixed(2)}</td>
    <td style="text-align: start;font-size: 14px;color: #141314;padding-bottom: 10px;padding-top: 10px;">${moment(
      data?.dueDate,
    ).format("YYYY-MM-DD")}</td>
    <td style="text-align: start;font-size: 14px;color: #141314;padding-bottom: 10px;padding-top: 10px;">-</td>
    <td>
    <div style="border-radius: 16px;background-color:${
      COLOR_AS_STATUS[data?.paymentPaidStatus || "Due"]
    };display: flex;height: 25px;width: 35px;">
    <p style="color: #ffffff;font-size: 12px;text-align: center;margin: auto;">${
      data?.paymentPaidStatus || "Due"
    }</p>
  </div>
    
  </td>
  </tr>`;
  });

  const billTable = ` <table style="width:100%;justify-content: center;border-spacing: 0px;">
  <tbody><tr>
      <th style="background-color: #f3f4f6;text-align: start;font-size: 11px;font-weight: 800;color: #141314;">S.NO</th>
      <th style="background-color: #f3f4f6;text-align: start;font-size: 11px;font-weight: 800;color: #141314;">AMOUNT</th>
      <th style="background-color: #f3f4f6;text-align: start;font-size: 11px;font-weight: 800;color: #141314;">CARD FEE
      </th>
      <th style="background-color: #f3f4f6;text-align: start;font-size: 11px;font-weight: 800;color: #141314;">TOTAL      </th>
      <th style="background-color: #f3f4f6;text-align: start;font-size: 11px;font-weight: 800;color: #141314;">DUE DATE
      </th>
      <th style="background-color: #f3f4f6;text-align: start;font-size: 11px;font-weight: 800;color: #141314;">METHOD</th>
      <th style="background-color: #f3f4f6;text-align: start;font-size: 11px;font-weight: 800;color: #141314;">STATUS</th>
  </tr>
  ${
    futurePayments?.length > 0
      ? itemsPayments?.toString()?.replace(/[,]/g, "")
      : ""
  }
  </tbody></table>`;
  const merger = header + invoiceTable + billTable;
  const main = `
  <!DOCTYPE html>
      <html lang="pt-BR">
      <head>
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />
      <link href="/css/app.css" rel="stylesheet" />
      </head>
      <body>
  <div style="background-color: #f5f7f9;font-family:sans-serif;padding: 10px;">
      <div style="background-color: #ffffff; box-shadow: 0 3px 10px rgb(0 0 0 / 0.5);padding:10px;border-radius: 5px;max-width: 800px;">
  ${merger}
  <p style="font-weight: 600; font-size: 14px; margin-left: 5px;">
  ${combinedText ? "Terms & Conditions" : ""}
  <p/>
    <div style="font-size: 12px;font-weight: 100;margin-top: 5px ,margin-left: 5px;">
     
  ${combinedText || ""}

  ${paymentButton || ""}
  <p/>
      </div>
      <div/>
      </div>
      </body></html>
      `;

  return main;
};

export const paymentRequestTemplate = (
  paymentLink: string,
  clientName: string,
  yourName: string,
  amountDue: any,
) => {
  return `<!DOCTYPE html>
  <html>
  <head>
    <title>Payment Request</title>
  </head>
  <body>
    <div style="max-width: 600px; margin: 0 auto; padding: 20px; border: 1px solid #ccc; border-radius: 5px; background-color: #f9f9f9; font-family: Arial, sans-serif; line-height: 1.6;">
      <h1>Payment Request</h1>
      <p>Dear ${clientName},</p>
      <p>I hope this email finds you well.</p>
      <p>I wanted to kindly remind you that the payment for [specific invoice/transaction/details] is now due or overdue. The total amount outstanding is $${amountDue}.</p>
      <p>Please click the button below to proceed with the payment:</p>
      <p style="text-align: center;">
        <a href="${paymentLink}" style="display: inline-block; padding: 10px 20px; text-decoration: none; background-color: #007bff; color: #fff; border-radius: 3px;">Pay Now</a>
      </p>
      <p>I understand that circumstances may arise, causing delays in payments. If there are any issues or concerns regarding the invoice or if you need any clarification, please don't hesitate to reach out.</p>
      <p>Your prompt attention to this matter would be greatly appreciated.</p>
      <p>Thank you for your cooperation and prompt action in resolving this matter.</p>

    </div>
  </body>
  </html>
  `;
};

export const paymentRequestLink = (paymentLink: string) => {
  return `
      <p>
        <a href="${paymentLink}" style="display: inline-block; padding: 10px 20px; text-decoration: none; background-color: #007bff; color: #fff; border-radius: 3px;">Pay Now</a>
      </p>
  `;
};

export const invoicesPrintInHtMl = (
  invoiceData: any,
  payableAmount: any,
  paymentLink = "",
  logoUrl = "",
) => {
  const termsCondition = invoiceData?.companyInvoiceSetting?.terms;
  const futurePayments =
    invoiceData?.paymentFuture || invoiceData?.futurePayments;
  const paymentButton = paymentLink
    ? `<p style="text-align: center;">
  <a href="${paymentLink}" style="display: inline-block; padding: 10px 20px; text-decoration: none; background-color: #007bff; color: #fff; border-radius: 3px;">Pay Now</a>
</p>`
    : "";
  const header = `
  <div>
    <div style="display: flex;justify-content: space-between;margin: 0px;">
      <div>
         ${logoUrl && `<img loading="lazy" src="${FILE_URL}/${logoUrl}" style="width:auto; height: 50px;" alt="logo">`}
          <p style="font-size: 12px;font-weight: 600;margin-top: 15px">${
            invoiceData?.invoiceNumber || ""
          }</p>
           <p style="font-size: 12px;font-weight: 600;margin-top: 2px">Inv Date ${
             moment(invoiceData?.invoiceDate).format("YYYY-MM-DD") || ""
           }</p>
          <p style="font-size: 28px;margin-top: 5px;margin-bottom: 5px;color: #141314;font-weight: 600;">
              $${payableAmount?.toFixed(2) || "00"}</p>
          <p style="font-size: 12px;font-weight: 100;margin-top: 5px;">Due Amount</p>
      </div>
  
      <div style="width: 100px;margin-left:auto">
        <div style="background-color:
          ${COLOR_AS_STATUS_FOR_HTML[invoiceData?.paymentPaidStatus || "Due"]} !important ;">
            <p style="font-size: 14px;color: #fff; text-align: center;padding: 8px; ">
              ${invoiceData?.paymentPaidStatus || "Due"}</p>
        </div>
      </div>
    </div>
  </div>

  <div style="height: 2px; background-color: !important #e6e8f0;margin-bottom: 10px;"></div>  
  <hr style="border: none; border-bottom: 2px solid #e6e8f0; color: #e6e8f0;" />
  `;

  const invoicesItems = invoiceData?.paymentInvoiceItems?.map((item: any) => {
    const total = item?.totalAmount || item?.total;
    return `<tr>
    <td style="font-size: 13px;color: #374151;padding-bottom: 10px;">${
      textEllipsis(item?.title, 20) || "-"
    }</td>
    <td style="font-size: 13px;color: #374151;padding-bottom: 10px;">${
      item?.quantity
    }</td>
    <td style="font-size: 13px;color: #374151;padding-bottom: 10px;">$${Number(
      item?.amount,
    )?.toFixed(2)}</td>
    <td style="font-size: 13px;color: #374151;padding-bottom: 10px;"></td>
    <td style="font-size: 13px;color: #374151;padding-bottom: 10px;">$${total?.toFixed(
      2,
    )}</td>
  </tr>`;
  });

  const invoiceTable = `<table style="width:100%;text-align: start;border-spacing: 0px">
  <tbody><tr style="font-family:sans-serif;">
      <td style="font-size: 12px;color: #374151;text-align: start;font-weight: 600;padding-bottom: 10px;">
          ITEM
      </td>
      <td style="font-size: 12px;color: #374151;text-align: start;font-weight: 600;padding-bottom: 10px;">
          QUANTITY</td>
      <td style="font-size: 12px;color: #374151;text-align: start;font-weight: 600;padding-bottom: 10px;">
          AMOUNT</td>
      <td></td>
      <td style="font-size: 12px;color: #374151;text-align: start;font-weight: 600;padding-bottom: 10px;">
          TOTAL</td>
  </tr>
  ${invoicesItems?.toString()?.replace(/[,]/g, "")}
  
  <tr>
      <td></td>
      <td style="font-size: 13px;color: #374151;padding-bottom: 10px;">Sub Total</td>
      <td></td>
      <td></td>
      <td style="font-size: 13px;color: #374151;padding-bottom: 10px;">$${
        invoiceData?.totalAmount?.toFixed(2) || 0
      }</td>
  </tr>
  <tr>
      <td></td>
      <td style="font-size: 13px;color: #141314; font-weight: 800;padding-bottom: 10px;">Total</td>
      <td></td>
      <td></td>
      <td style="font-size: 13px;color: #141314; font-weight: 800;padding-bottom: 10px;">$${(
        (invoiceData?.totalAmount || 0) + (invoiceData?.creditFee || 0)
      )?.toFixed(2)}</</td>
  </tr>
  <tr>
      <td></td>
      <td style="font-size: 13px;color: #374151;padding-bottom: 10px;">Credit Card Processing Fee :</td>
      <td></td>
      <td></td>
      <td style="font-size: 13px;color: #374151;padding-bottom: 10px;">$${
        invoiceData?.creditFee?.toFixed(2) || 0
      }</td>
  </tr>
  
  <tr>
      <td></td>
      <td style="font-size: 13px;color: #374151;padding-bottom: 10px;">Payment Made</td>
      <td></td>
      <td></td>
      <td style="font-size: 13px;color:#d14343;padding-bottom: 10px;">-$${
        invoiceData?.paymentMade?.toFixed(2) || 0
      }</td>
  </tr>
  
  <tr style="background-color: #cccccc;">
      <td></td>
      <td style="font-size: 13px;color: #141314; font-weight: 800;padding-top: 5px;padding-bottom: 5px;">
          Balance Due</td>
      <td></td>
      <td></td>
      <td style="font-size: 13px;color: #141314; font-weight: 800;padding-top: 5px;padding-bottom: 5px;">
     $${(
       (invoiceData?.totalAmount || 0) - (invoiceData?.paymentMade || 0)
     )?.toFixed(2)}</td>
  </tr>
    <tr>
      <td></td>
      <td style="font-size: 13px;color: #374151;padding-bottom: 10px;">Invoice Date :</td>
      <td></td>
      <td></td>
      <td style="font-size: 13px;color: #374151;padding-bottom: 10px;">${
        moment(invoiceData?.invoiceDate).format("YYYY-MM-DD") || 0
      }</td>
  </tr>
    <tr>
      <td></td>
      <td style="font-size: 13px;color: #374151;padding-bottom: 10px;">Due Date :</td>
      <td></td>
      <td></td>
      <td style="font-size: 13px;color: #d14343;padding-bottom: 10px;">${
        moment(invoiceData?.dueDate).format("YYYY-MM-DD") || 0
      }</td>
  </tr>
  </tbody></table>
  <br>
  <hr style="border: none; border-bottom: 2px solid #e6e8f0; color: #e6e8f0;" />
  `;

  const itemsPayments = futurePayments?.map((data: any, index: any) => {
    return `<tr>
    <td style="text-align: start;font-size: 14px;color: #141314;padding-bottom: 10px;padding-top: 10px;">${
      index + 1
    }</td>
    <td style="text-align: start;font-size: 14px;color: #141314;padding-bottom: 10px;padding-top: 10px;">$${(
      data?.amount || 0
    )?.toFixed(2)}</td>
    <td style="text-align: start;font-size: 14px;color: #141314;padding-bottom: 10px;padding-top: 10px;">$${(
      data?.cardFee || 0
    )?.toFixed(2)}</td>
    <td style="text-align: start;font-size: 14px;color: #141314;padding-bottom: 10px;padding-top: 10px;">${(
      (data?.amount || 0) + (data?.cardFee || 0)
    )?.toFixed(2)}</td>
    <td style="text-align: start;font-size: 14px;color: #141314;padding-bottom: 10px;padding-top: 10px;">${moment(
      data?.dueDate,
    ).format("YYYY-MM-DD")}</td>
    <td style="text-align: start;font-size: 14px;color: #141314;padding-bottom: 10px;padding-top: 10px;">${data?.paymentMethod}</td>
    <td>
    <div style="border-radius: 16px;background-color:${
      COLOR_AS_STATUS_FOR_HTML[data?.paymentPaidStatus || "Due"]
    } !important;display: flex;height: 25px;width: 35px;">
    <p style="color: #fff;font-size: 12px;text-align: center;margin: auto;">${
      data?.paymentPaidStatus || "Due"
    }</p>
  </div>
    
  </td>
  </tr>`;
  });

  const billTable = ` <table style="width:100%;justify-content: center;border-spacing: 0px;">
  <tbody><tr>
      <th style="background-color: #f3f4f6;text-align: start;font-size: 11px;font-weight: 800;color: #141314;">S.NO</th>
      <th style="background-color: #f3f4f6;text-align: start;font-size: 11px;font-weight: 800;color: #141314;">AMOUNT</th>
      <th style="background-color: #f3f4f6;text-align: start;font-size: 11px;font-weight: 800;color: #141314;">CARD FEE
      </th>
      <th style="background-color: #f3f4f6;text-align: start;font-size: 11px;font-weight: 800;color: #141314;">TOTAL      </th>
      <th style="background-color: #f3f4f6;text-align: start;font-size: 11px;font-weight: 800;color: #141314;">DUE DATE
      </th>
      <th style="background-color: #f3f4f6;text-align: start;font-size: 11px;font-weight: 800;color: #141314;">METHOD</th>
      <th style="background-color: #f3f4f6;text-align: start;font-size: 11px;font-weight: 800;color: #141314;">STATUS</th>
  </tr>
  ${
    futurePayments?.length > 0
      ? itemsPayments?.toString()?.replace(/[,]/g, "")
      : ""
  }
  </tbody></table>`;
  const merger = header + invoiceTable + billTable;
  const main = `
  <!DOCTYPE html>
      <html lang="pt-BR">
      <head>
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />
      <link href="/css/app.css" rel="stylesheet" />
      </head>
      <body>
  <div style="background-color: #f5f7f9;font-family:sans-serif;padding: 10px;">
      <div style="background-color: #ffffff; box-shadow: 0 3px 10px rgb(0 0 0 / 0.5);padding:10px;border-radius: 5px;max-width: 800px;">
  ${merger}
  <p style="font-weight: 600; font-size: 14px; margin-left: 5px;">
  ${termsCondition ? "Terms & Conditions" : ""}
  <p/>
    <div style="font-size: 12px;font-weight: 100;margin-top: 5px ,margin-left: 5px;">
     
  ${termsCondition || ""}

  ${paymentButton || ""}
  <p/>
      </div>
      <div/>
      </div>
      </body></html>
      `;

  return main;
};

export const smsTypeConstant = {
  None: "None",
  Schedule: "Schedule",
  Draft: "Draft",
};
