import { ITimeLine } from "pages/customer/as-activity/query/useFetchTimeLine";
import client from "utils/ApiClient";
import API_URLS from "./endPoints";

export interface IToggleAsStatus {
  appointmentSetterId: number;
  isSms: boolean | null;
  isEmail: boolean | null;
}

async function fetchDashboardMainCard(): Promise<ITimeLine> {
  const url = `${API_URLS.FETCH_DASHBOARD_MAIN_CARD}`;
  return client.get(url);
}

async function fetchComunication(): Promise<ITimeLine> {
  const url = `${API_URLS.FETCH_COMUNICATION}`;
  return client.get(url);
}

export { fetchComunication, fetchDashboardMainCard };

