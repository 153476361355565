/* eslint-disable react-hooks/rules-of-hooks */
import { useQuery } from "react-query";
import { fetchComunication } from "services/dashboardMaimCard.service";
import { QueryKeys } from "utils/QueryKeys";

export interface IComunication {
  smsInCount: number;
  smsOutCount: number;
  emailInCount: number;
  emailOutCount: number;
  totacallInCountlAs: number;
  callOutCount: number;
}
export interface IComunicationResponse {
  data?: IComunication[];
  statusCode: number;
}

async function getComunication(): Promise<any> {
  try {
    const response: any = await fetchComunication();

    if (response.statusCode === 200) {
      return response;
    }
    return {
      statusCode: 404,
      totalCount: 0,
    };
  } catch (error) {
    return Promise.reject(error);
  }
}

const useFetchComunication = (enabled = false) => {
  const cacheKey = [QueryKeys.getComunication];
  return useQuery(
    cacheKey,
    () => {
      return getComunication();
    },
    {
      enabled,
    },
  );
};

export { useFetchComunication };

