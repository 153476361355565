/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAllCount } from "pages/user/contacts/details/query/useFetchTotalCounts";
import { RootState, useAppDispatch } from "redux/store";
import {
  IContactsReducer,
  IInvoiceContact,
  IPaginationList,
  IPayload,
} from "./interface";

const initialState: IContactsReducer = {
  columnIds: [],
  contact: null,
  contactIds: [],
  customersIds: [],
  myListId: null,
  deleteIds: [],
  customerDeleteIds: [],
  paginationList: [],
  selectedContact: {} as IPaginationList,
  allCounts: {} as IAllCount,
  selectedContacts: [],
  type: "normal",
  campId: 0,
  invoiceId: {} as IInvoiceContact,
  invoiceNumber: "",
  addPayments: false,
  invoiceOpenFrom: "",
  toggles: {
    collaborators: false,
    deals: false,
    task: false,
    appointment: false,
    addContract: false,
    campaigns: false,
    formEntries: false,
    agreement: false,
    payments: false,
  },
  openEmail: false,
  openSms: false,
  openVideo: false,
  openVoice: false,
  filterParams: null,
  tableColorData: null,
  openColorPicker: false,
  openAssignTo: false,
  assignTagOpen: false,
  openSequence: false,
  contactEmailList: null,
  contactNumberList: null,
};

interface IAction {
  payload: IPayload;
  type: string;
}

const contactSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    setTableColorData: (state, action: IAction) => {
      state.tableColorData = action.payload.tableColorData;
    },
    setOpenColorPicker: (state, action: IAction) => {
      state.openColorPicker = action.payload.openColorPicker;
    },
    setColumnIds: (state, action: IAction) => {
      state.columnIds = action.payload.columnIds;
    },
    setAddPayments: (state, action: IAction) => {
      state.addPayments = action.payload.addPayments;
    },
    setInvoiceFrom: (state, action: IAction) => {
      state.invoiceOpenFrom = action.payload.invoiceOpenFrom;
    },
    setContactEmailList: (state, action: IAction) => {
      state.contactEmailList = action.payload.contactEmailList;
    },
    setContactNumberList: (state, action: IAction) => {
      state.contactNumberList = action.payload.contactNumberList;
    },
    setInvoiceId: (state, action: IAction) => {
      state.invoiceId = action.payload.invoiceId || ({} as IInvoiceContact);
      state.invoiceNumber = action.payload.invoiceNumber || "";
    },
    setContact: (state, action: IAction) => {
      state.contact = action.payload.contact;
      state.type = "normal";
    },
    setCallCampaignContact: (state, action: IAction) => {
      state.contact = action.payload.contact;
      state.type = "campaign";
    },
    setFilterParams: (state, action: IAction) => {
      state.filterParams = action.payload.filterParams;
      state.type = "campaign";
    },
    setContactType: (state) => {
      state.type = "normal";
    },
    setCampaignId: (state, action: IAction) => {
      state.campId = action.payload.campId;
    },
    setContactIds: (state, action: IAction) => {
      const { contactId } = action.payload;
      const findContact = state.contactIds?.find((i) => i === contactId);
      if (findContact) {
        state.contactIds =
          state.contactIds?.filter((i) => i !== contactId) || [];
      } else {
        state.contactIds?.push(contactId);
      }
    },
    setCustomerIds: (state, action: PayloadAction<{ contactId: number }>) => {
      const { contactId } = action.payload;
      const findContact = state.customersIds?.find((i: any) => i === contactId);
      if (findContact) {
        state.customersIds =
          state.customersIds?.filter((i: any) => i !== contactId) || [];
      } else {
        state.customersIds?.push(contactId);
      }
    },
    setAllContactIds: (state, action: IAction) => {
      const { checked, contactIds } = action.payload;
      if (checked) {
        state.contactIds = contactIds;
      } else {
        state.contactIds = [];
      }
    },
    setAllCusomersIds: (
      state,
      action: PayloadAction<{ checked: boolean; contactIds: number[] }>,
    ) => {
      const { checked, contactIds } = action.payload;
      if (checked) {
        state.customersIds = contactIds;
      } else {
        state.customersIds = [];
      }
    },
    setContactMyListId: (state, action: IAction) => {
      const hasId: any = action.payload.myListId;
      state.myListId = action.payload.myListId;
    },

    setActivity: (state, action: IAction) => {
      state.activity = action.payload.activity;
    },
    removeActivity: (state) => {
      state.activity = null;
    },

    setAllActivity: (state, action: IAction) => {
      state.allActivity = action.payload.allActivity;
    },
    setOpenEmailToggle: (state, action: IAction) => {
      state.openEmail = action.payload.openEmail;
    },
    setOpenSmsToggle: (state, action: IAction) => {
      state.openSms = action.payload.openSms;
    },
    setOpenInfoToggle: (state, action: IAction) => {
      state.openInfo = action.payload.openInfo;
    },
    setOpenVideoToggle: (state, action: IAction) => {
      state.openVideo = action.payload.openVideo;
    },
    setOpenVoiceToggle: (state, action: IAction) => {
      state.openVoice = action.payload.openVoice;
    },
    setOpenAssignToToggle: (state, action: IAction) => {
      state.openAssignTo = action.payload.openAssignTo;
    },
    setOpenAssignTagToggle: (state, action: IAction) => {
      state.assignTagOpen = action.payload.assignTagOpen;
    },
    setOpenMoveToOtherToggle: (state, action: IAction) => {
      state.openMoveOther = action.payload.openMoveOther;
    },
    setOpenSequenceToggle: (state, action: IAction) => {
      state.openSequence = action.payload.openSequence;
    },
    removeAllActivity: (state) => {
      state.allActivity = null;
    },
    removeContactMyListsIds: (state, action: PayloadAction<{ id: number }>) => {
      const { id } = action.payload;
      state.myListId = null;
    },
    setDeleteIds: (state, action: IAction) => {
      const { deleteIds } = action.payload;
      state.deleteIds = deleteIds;
    },
    setCustomerDeleteIds: (state, action: IAction) => {
      const { deleteIds } = action.payload;
      state.customerDeleteIds = deleteIds;
    },

    setPaginationList: (state, action: IAction) => {
      const { paginationList } = action.payload;
      state.paginationList = paginationList;
    },
    setSelectedContact: (state, action: IAction) => {
      const { selectedContact } = action.payload;
      state.selectedContact = selectedContact;
    },
    setAllCounts: (state, action: IAction) => {
      const { allCounts } = action.payload;
      state.allCounts = allCounts;
    },
    resetContactIds: (state) => {
      state.contactIds = [];
    },
    resetContactMyListsId: (state) => {
      state.myListId = null;
    },

    // selectedIds
  },
});

const contactState = (state: RootState) => state.contacts;

export const selectContactIds = createSelector(
  [contactState],
  (state) => state.contactIds || [],
);

export const selectCustomersIds = createSelector(
  [contactState],
  (state) => state.customersIds || [],
);
export const {
  setColumnIds,
  setContact,
  setContactIds,
  setActivity,
  removeActivity,
  setTableColorData,
  setAllActivity,
  setOpenColorPicker,
  setOpenInfoToggle,
  removeAllActivity,
  setContactMyListId,
  removeContactMyListsIds,
  setDeleteIds,
  setCustomerDeleteIds,
  setPaginationList,
  setSelectedContact,
  setAllCounts,
  resetContactIds,
  setAllContactIds,
  resetContactMyListsId,
  setCallCampaignContact,
  setCampaignId,
  setAddPayments,
  setInvoiceFrom,
  setInvoiceId,
  setFilterParams,
  setContactType,
  setOpenEmailToggle,
  setOpenSmsToggle,
  setOpenVideoToggle,
  setOpenVoiceToggle,
  setOpenAssignToToggle,
  setOpenAssignTagToggle,
  setOpenMoveToOtherToggle,
  setOpenSequenceToggle,
  setContactEmailList,
  setAllCusomersIds,
  setContactNumberList,
  setCustomerIds,
} = contactSlice.actions;

export const useContactActions = () => {
  const dispatch = useAppDispatch();
  return {
    setFilterParams: (payload: IPayload) => dispatch(setFilterParams(payload)),
    setOpenColorPicker: (payload: IPayload) =>
      dispatch(setOpenColorPicker(payload)),
    setContactType: () => dispatch(setContactType()),
    setOpenEmailToggle: (payload: IPayload) =>
      dispatch(setOpenEmailToggle(payload)),
    setOpenSequenceToggle: (payload: IPayload) =>
      dispatch(setOpenSequenceToggle(payload)),
    setOpenMoveToOtherToggle: (payload: IPayload) =>
      dispatch(setOpenMoveToOtherToggle(payload)),
    setOpenAssignTagToggle: (payload: IPayload) =>
      dispatch(setOpenAssignTagToggle(payload)),
    setOpenInfoToggle: (payload: IPayload) =>
      dispatch(setOpenInfoToggle(payload)),
    setTableColorData: (payload: IPayload) =>
      dispatch(setTableColorData(payload)),
    setOpenSmsToggle: (payload: IPayload) =>
      dispatch(setOpenSmsToggle(payload)),
    setOpenVideoToggle: (payload: IPayload) =>
      dispatch(setOpenVideoToggle(payload)),
    setOpenVoiceToggle: (payload: IPayload) =>
      dispatch(setOpenVoiceToggle(payload)),
    setOpenAssignToToggle: (payload: IPayload) =>
      dispatch(setOpenAssignToToggle(payload)),
    setContactEmailList: (payload: IPayload) =>
      dispatch(setContactEmailList(payload)),
    setContactNumberList: (payload: IPayload) =>
      dispatch(setContactNumberList(payload)),
    setAddPayments: (payload: IPayload) => dispatch(setAddPayments(payload)),
    setColumnIds: (payload: IPayload) => dispatch(setColumnIds(payload)),
    setCallCampaignContact: (payload: IPayload) =>
      dispatch(setCallCampaignContact(payload)),
    setContact: (payload: IPayload) => dispatch(setContact(payload)),
    setActivity: (payload: IPayload) => dispatch(setActivity(payload)),
    removeActivity: () => dispatch(removeActivity()),
    setAllActivity: (payload: IPayload) => dispatch(setActivity(payload)),
    removeAllActivity: () => dispatch(removeActivity()),
    setDeleteIds: (payload: IPayload) => dispatch(setDeleteIds(payload)),
    setCustomerDeleteIds: (payload: IPayload) =>
      dispatch(setCustomerDeleteIds(payload)),

    setPaginationList: (payload: IPayload) =>
      dispatch(setPaginationList(payload)),
    setSelectedContact: (payload: IPayload) =>
      dispatch(setSelectedContact(payload)),
    setAllCounts: (payload: IPayload) => dispatch(setAllCounts(payload)),
    resetContactIds: () => dispatch(resetContactIds()),
    setContactMyListId: (payload: IPayload) =>
      dispatch(setContactMyListId(payload)),
    resetContactMyListsId: () => dispatch(resetContactMyListsId()),
    setCampaignId: (payload: IPayload) => dispatch(setCampaignId(payload)),
    setInvoiceFrom: (payload: IPayload) => dispatch(setInvoiceFrom(payload)),
    setInvoiceId: (payload: IPayload) => dispatch(setInvoiceId(payload)),
    setContactIds: (payload: IPayload) => dispatch(setContactIds(payload)),
    setAllContactIds: (payload: IPayload) =>
      dispatch(setAllContactIds(payload)),
  };
};
const { reducer } = contactSlice;

export default reducer;
