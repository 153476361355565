/* eslint-disable react/no-unescaped-entities */
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import HistoryIcon from "@mui/icons-material/History";
import {
    Box,
    IconButton,
    Popover,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import useUserInfo from "hooks/useUserInfo";
import { useFetchComunication } from "pages/admin/dashboard/quary/useFetchComunicationCounts";
import { useState } from "react";
import palette from "theme/palette";

function DraggableButton() {
    const [position, setPosition] = useState({ x: 1295, y: 704 });
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const { isLoggedIn } = useUserInfo();

    const {
        data: comunicationCount,
        isLoading: isASLoadingComunication,
        refetch,
    } = useFetchComunication();

    const handleMouseDown = (e: any) => {
        const initialX = e.clientX - position.x;
        const initialY = e.clientY - position.y;

        const handleMouseMove = (e: any) => {
            setPosition({
                x: e.clientX - initialX,
                y: e.clientY - initialY,
            });
        };

        window.addEventListener("mousemove", handleMouseMove);

        window.addEventListener("mouseup", () => {
            window.removeEventListener("mousemove", handleMouseMove);
        });
    };

    const handleDoubleClick = (e: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(e.currentTarget);
        refetch();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <>
            {isLoggedIn ? (
                <>
                    <Tooltip title="DoubleClick To Open">
                        <IconButton
                            sx={{
                                position: "absolute",
                                left: position.x,
                                top: position.y,
                                zIndex: 1000,
                                cursor: "grab",

                                backgroundColor: palette.info.main,
                                color: "#fff",
                                "&:hover": {
                                    color: palette.info.main,
                                },
                            }}
                            onDoubleClick={handleDoubleClick}
                            onMouseDown={handleMouseDown}
                        >
                            <HistoryIcon />
                        </IconButton>
                    </Tooltip>
                    <Popover
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                        id={id}
                        open={open}
                        sx={{
                            "& .MuiPopover-paper": {
                                // maxHeight: 300,
                                overflow: "auto",
                                padding: 2,
                            },
                        }}
                        transformOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        onClose={handleClose}
                    >
                        <Typography variant="h6">Today's History</Typography>
                        <Box>
                            <Stack
                                alignItems="center"
                                direction="column"
                                gap={1}
                                paddingTop={1}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        border: "solid 1px #27AE60",
                                        padding: "5px 6px",
                                        marginTop: "3px",
                                        borderRadius: "5px",
                                    }}
                                >
                                    <ArrowDownwardIcon
                                        sx={{
                                            fontSize: { xs: "12px", lg: "16px" },
                                            color: "inherit",
                                            mr: "5px",
                                            mb: "2px",
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: { xs: "12px", lg: "16px" },
                                            color: "inherit",
                                        }}
                                    >
                                        Calls : {comunicationCount?.data?.callInCount || 0}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        border: "solid 1px #FF264A",
                                        padding: "5px 6px",
                                        marginTop: "3px",
                                        borderRadius: "5px",
                                    }}
                                >
                                    <ArrowUpwardIcon
                                        sx={{
                                            fontSize: { xs: "12px", lg: "16px" },
                                            color: "inherit",
                                            mr: "5px",
                                            mb: "2px",
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: { xs: "12px", lg: "16px" },
                                            color: "inherit",
                                        }}
                                    >
                                        Calls : {comunicationCount?.data?.callOutCount || 0}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        border: "solid 1px #27AE60",
                                        padding: "5px 6px",
                                        marginTop: "3px",
                                        borderRadius: "5px",
                                    }}
                                >
                                    <ArrowDownwardIcon
                                        sx={{
                                            fontSize: { xs: "12px", lg: "16px" },
                                            color: "inherit",
                                            mr: "5px",
                                            mb: "2px",
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: { xs: "12px", lg: "16px" },
                                            color: "inherit",
                                        }}
                                    >
                                        Emails : {comunicationCount?.data?.emailInCount || 0}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        border: "solid 1px #FF264A",
                                        padding: "5px 6px",
                                        marginTop: "3px",
                                        borderRadius: "5px",
                                    }}
                                >
                                    <ArrowUpwardIcon
                                        sx={{
                                            fontSize: { xs: "12px", lg: "16px" },
                                            color: "inherit",
                                            mr: "5px",
                                            mb: "2px",
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: { xs: "12px", lg: "16px" },
                                            color: "inherit",
                                        }}
                                    >
                                        Emails : {comunicationCount?.data?.emailOutCount || 0}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        border: "solid 1px #27AE60",
                                        padding: "5px 6px",
                                        marginTop: "3px",
                                        borderRadius: "5px",
                                    }}
                                >
                                    <ArrowDownwardIcon
                                        sx={{
                                            fontSize: { xs: "12px", lg: "16px" },
                                            color: "inherit",
                                            mr: "5px",
                                            mb: "2px",
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: { xs: "12px", lg: "16px" },
                                            color: "inherit",
                                        }}
                                    >
                                        Sms : {comunicationCount?.data?.smsInCount || 0}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        border: "solid 1px #FF264A",
                                        padding: "5px 6px",
                                        marginTop: "3px",
                                        borderRadius: "5px",
                                    }}
                                >
                                    <ArrowUpwardIcon
                                        sx={{
                                            fontSize: { xs: "12px", lg: "16px" },
                                            color: "inherit",
                                            mr: "5px",
                                            mb: "2px",
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: { xs: "12px", lg: "16px" },
                                            color: "inherit",
                                        }}
                                    >
                                        Sms : {comunicationCount?.data?.smsOutCount || 0}
                                    </Typography>
                                </Box>
                            </Stack>
                        </Box>
                    </Popover>
                </>
            ) : null}
        </>
    );
}

export default DraggableButton;
